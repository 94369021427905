import { useState } from "react";

import PersonalInfo from "../../components/section/PersonalInfo";
import CompanyInfo from "../../components/section/CompanyInfo";
// import Security from "../../components/section/Security";

const MyAccount = () => {
  const [activeButton, setActiveButton] = useState(1);

  const handleButtonClick = (buttonIndex) => {
    setActiveButton(buttonIndex);
  };

  const getButtonStyle = (buttonIndex) => {
    return (
      activeButton === buttonIndex &&
      "text-white transition-colors duration-200"
    );
  };

  const [confirmation, setConfirmation] = useState(false);

  const getConfirmation = (value) => {
    setConfirmation(value);
  };

  return (
    <div className="w-full flex justify-center bg-[#f8f8ff] sm:bg-white ">
      <div className="relative w-[95%] sm:w-[90%] max-w-[1500px] sm:bg-[#f8f8ff]  overflow-y-auto h-[calc(100vh-61px)] sm:h-[calc(100vh-73px)] md:h-[calc(100vh-69px)] lg:h-[calc(100vh-79px)] py-5 md:py-10">
        {confirmation && (
          <div className="absolute top-2 right-2 bg-white w-[300px] h-12 rounded border border-green-500 text-green-500 flex items-center justify-center">
            <h1>Confirmed</h1>
          </div>
        )}
        <div className="w-full flex justify-center text-[#3d5984]">
          <div className="w-[600px] flex flex-col space-y-5 md:space-y-10 items-center">
            <div className="w-full  rounded-md bg-white text-center px-5 py-5 md:py-8 space-y-2 md:space-y-5">
              <h1 className="text-lg md:text-xl font-bold">MY ACCOUNT</h1>
              <p className="text-xs sm:text-sm md:text-base">
                On this page you can add information <br /> about you and your
                company.
              </p>
            </div>
            <div className="w-[85%] bg-white p-1 rounded-full border border-[#3d5984]/50">
              <div className="flex items-center relative w-full rounded-full overflow-hidden z-10 text-xs sm:text-sm">
                <button
                  className={`px-4 py-2  ${getButtonStyle(
                    1
                  )} w-1/2 hover:bg-[#3d5984]/5 rounded-full transition-colors duration-200`}
                  onClick={() => handleButtonClick(1)}
                >
                  Personal
                </button>
                <button
                  className={`px-4 py-2  ${getButtonStyle(
                    2
                  )} w-1/2 hover:bg-[#3d5984]/5 rounded-full transition-colors duration-200`}
                  onClick={() => handleButtonClick(2)}
                >
                  Company
                </button>
                {/* <button
                  className={`px-4 py-2 ${getButtonStyle(
                    3
                  )} w-1/3 hover:bg-[#3d5984]/5 rounded-full transition-colors duration-200`}
                  onClick={() => handleButtonClick(3)}
                >
                  Security
                </button> */}

                {/* Sliding background effect using Tailwind CSS classes */}
                <div
                  className="absolute bottom-0 left-0 h-2 bg-[#3d5984] transition-transform ease-in-out duration-200 h-full w-1/2 rounded-full -z-10"
                  style={{
                    transform: `translateX(${(activeButton - 1) * 100}%)`,
                  }}
                />
              </div>
            </div>
            <div className="w-full bg-white  rounded-md p-5">
              {activeButton === 1 && (
                <PersonalInfo onSuccess={getConfirmation} />
              )}
              {activeButton === 2 && <CompanyInfo />}
              {/* {activeButton === 3 && <Security />} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
