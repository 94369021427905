import { useState, useEffect, useRef } from "react";

import context from "./context";

const TendersContextProvider = (props) => {
  const telegramTenderPage =
    window.location.pathname.startsWith("/latest-tenders/telegram") ||
    window.location.pathname.startsWith("/myfeed/telegram");
  const telegramDetailPage = window.location.pathname.startsWith(
    "/tender/telegram"
  );

  const userToken = sessionStorage.getItem("token");

  const [selectedPlan, setSelectedPlan] = useState("");

  const [token, setToken] = useState(userToken || "");

  const [popularCompanies, setPopularCompanies] = useState([]);

  const [finishedRegistration, setFinishedRegistration] = useState(false);

  const [showSearchDarwer, setShowSearchDrawer] = useState(false);

  const [popularCategories, setPopularCategories] = useState([]);

  const [tendersPopularCategories, setTendersPopularCategories] = useState([]);

  const [searchIsClicked, setSearchIsClicked] = useState(false);

  const [cbePhone, setCbePhone] = useState("");
  const [cbeToken, setCbeToken] = useState();

  const showDrawerHandler = () => {
    setShowSearchDrawer((prev) => {
      return !prev;
    });
  };

  // TENDER DETAILS  ////////////////////////////////////////////////////////////////////////////

  const [showTenderDetail, setShowTenderDetail] = useState(false);
  const [prevRoute, setPrevRoute] = useState(
    telegramTenderPage || telegramDetailPage
      ? "/latest-tenders/telegram"
      : "/tenders"
  );

  const [scrollCoordinates, setScrollCoordinates] = useState(0);

  /////// FETCH TENDERS //////////////////////////////////////////////////////////////////////

  const [tenders, setTenders] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isFetchingTenders, setIsFetchingTenders] = useState(false);
  const [scrollCount, setScrollCount] = useState(1);
  const [fetchLimiter, setFetchLimiter] = useState(null);
  const [apiPath, setApiPath] = useState("");
  const [telegramApiPath, setTelegramApiPath] = useState("");
  const [searchParams, setSearchParams] = useState({
    title: "",
    categoryId: "",
    regionId: "",
    languageId: "",
    startDate: "",
    endDate: "",
    status: "",
    page: "",
    companyId: "",
  });

  const shouldLoadTendersRef = useRef(true);

  const setShouldLoadTenders = (value) => {
    shouldLoadTendersRef.current = value;
  };

  useEffect(() => {
    setSelectedCategory("");

    if (!window.location.pathname.startsWith("/company/")) {
      setSearchParams({
        title: "",
        categoryId: "",
        regionId: "",
        languageId: "",
        startDate: "",
        endDate: "",
        status: "",
        page: "",
        companyId: "",
      });
    }
  }, [apiPath]);

  useEffect(() => {
    setScrollCount(1);
    setTenders([]);
  }, [selectedCategory, apiPath, telegramApiPath]);

  // fetching

  const fetchData = () => {
    let auth_data;

    if (telegramTenderPage) {
      // auth_data =
      //   "query_id%3DAAFnWIsXAAAAAGdYixeizTjn%26user%3D%257B%2522id%2522%253A395008103%252C%2522first_name%2522%253A%2522Biruk%2520K.%2522%252C%2522last_name%2522%253A%2522%2522%252C%2522username%2522%253A%2522K_Biruk%2522%252C%2522language_code%2522%253A%2522en%2522%252C%2522is_premium%2522%253Atrue%252C%2522allows_write_to_pm%2522%253Atrue%257D%26auth_date%3D1690538487%26hash%3D8c4f1fa8320e74cb08c4322d97aae9f017ab412dd1405cd01302bc4c8c67c7cb";
      auth_data = encodeURIComponent(window.Telegram.WebApp.initData);
    }
    setIsFetchingTenders(true);
    var headers = new Headers();
    headers.append("X-XSRF-TOKEN", "csrf-cookie");
    headers.append("Accept", "application/json");
    headers.append("Authorization", `Bearer ${token}`);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: headers,
    };
    fetch(
      telegramTenderPage
        ? `https://api.ichereta.com/api/telegram/${telegramApiPath}?data_check_string=${auth_data}&page=${scrollCount}`
        : `https://api.ichereta.com/api/${apiPath}?title=${searchParams.title}&category_id=${searchParams.categoryId}&region_id=${searchParams.regionId}&language_id=${searchParams.languageId}&start_date=${searchParams.startDate}&end_date=${searchParams.endDate}&status_id=${searchParams.status}&page=${scrollCount}&company_id=${searchParams.companyId}`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 401) {
          sessionStorage.clear();
          window.location.href = "/";
        }

        return response.json();
      })
      .then((result) => {
        //case to be removed
        if (result.status === true) {
          setFetchLimiter(result.tenders.next_page_url);

          if (scrollCount === 1) {
            setTenders(result.tenders.data);
          } else {
            setTenders((prevTenders) => [
              ...prevTenders,
              ...result.tenders.data,
            ]);
          }
        }
        setIsFetchingTenders(false);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    if (
      (apiPath !== "" && shouldLoadTendersRef.current) ||
      (tenders.length === 0 && apiPath !== "") ||
      telegramTenderPage
    ) {
      let timer;
      setIsFetchingTenders(true);

      const debounceFetchData = () => {
        clearTimeout(timer);
        timer = setTimeout(fetchData, 500);
      };

      debounceFetchData();

      return () => {
        clearTimeout(timer);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, scrollCount, apiPath, telegramApiPath]);

  // GET POPULAR CATEGORIES /////////////////////////////////////////////////////////////////////

  const [getingPopularCategories, setGetingPopularCategories] = useState(false);

  const popularCategoriesRef = useRef([0]);

  const setPopularCategoriesRef = (value) => {
    popularCategoriesRef.current = value;
  };

  const getPopularCategories = (path) => {
    setGetingPopularCategories(true);
    var headers = new Headers();
    headers.append("X-XSRF-TOKEN", "csrf-cookie");
    headers.append("Accept", "application/json");
    headers.append("Authorization", `Bearer ${userToken}`);

    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: headers,
    };

    fetch(
      `https://api.ichereta.com/api/tender/popular/categories?path=${path}`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 401) {
          sessionStorage.clear();
          window.location.href = "/";
        }
        return response.json();
      })
      .then((result) => {
        setGetingPopularCategories(false);

        if (path === "") {
          setTendersPopularCategories(result);
        } else {
          setPopularCategories(result);
          setPopularCategoriesRef(result);
        }
      })
      .catch((error) => {
        setGetingPopularCategories(false);
        console.log("error", error);
      });
  };

  // SEARCHING FILTER ///////////////////////////////////////////////////////////////////////////

  const [categoryLists, setCategoryLists] = useState([]);
  const [regions, setRegions] = useState([]);
  const [language, setLanguage] = useState([]);

  const [feedCategoryLists, setFeedCategoryLists] = useState([]);
  const [feedRegions, setFeedRegions] = useState([]);
  const [feedLanguage, setFeedLanguage] = useState([]);

  const [isFetchingLangAndReg, setIsFetchingLangAndReg] = useState(false);
  const [isFetchingFeedCategories, setIsFetchingFeedCategories] = useState(
    false
  );

  const [tendersCategoryLists, setTendersCategoryLists] = useState([]);
  const [tendersRegions, setTendersRegions] = useState([]);
  const [tendersLanguage, setTendersLanguage] = useState([]);

  const [searchingTitle, setSearchingTitle] = useState("");
  const [searchingRegionId, setSearchingRegionId] = useState("");
  const [searchingLanguageId, setSearchingLanguageId] = useState("");
  const [searchingCategoryId, setSearchingCategoryId] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const SearchTenders = () => {
    setSearchParams({
      ...searchParams,
      title: searchingTitle,
      categoryId: searchingCategoryId,
      regionId: searchingRegionId,
      languageId: searchingLanguageId,
      status: selectedStatus,
    });
    setScrollCount(1);
    setTenders([]);
    setSearchIsClicked(true);
    showDrawerHandler();
  };

  // SAVE COUNT /////////////////////////////////////////////////////////////////////////////////

  const [savedCount, setSavedCount] = useState(0);
  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    companyName: "",
    tin: "",
    isActive: "",
    amount: "",
    latest_plan: "",
  });

  const [isVerified, setIsVerified] = useState({
    phone: true,
    email: true,
  });

  const getUserData = () => {
    setSavedCount(0);
    var headers = new Headers();
    headers.append("X-XSRF-TOKEN", "csrf-cookie");
    headers.append("Accept", "application/json");
    headers.append("Authorization", `Bearer ${userToken}`);

    var requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    fetch("https://api.ichereta.com/api/client/user-info", requestOptions)
      .then((response) => {
        if (response.status === 401) {
          sessionStorage.clear();
          window.location.href = "/";
        }

        return response.json();
      })
      .then((result) => {
        setSavedCount(result.unseen_tender_count);

        setUserInfo({
          name: result.name,
          email: result.email,
          phone: result.phone,
          address: result.address,
          companyName: result.company_name,
          tin: result.tin,
          isActive: result.is_active,
          amount: result.amount,
          latest_plan: result.latest_plan,
        });
        setIsVerified({
          phone: result.is_phone_verified,
          email: result.is_email_verified,
        });
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    if (userToken && !telegramTenderPage && !telegramDetailPage) {
      getUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  // ALERT STATUS //////////////////////////////////////////////////////////////////////////////

  const [channels, setChannels] = useState({
    email: "",
    sms: "",
    telegram: "",
  });

  const getAlertStatus = () => {
    var headers = new Headers();
    headers.append("X-XSRF-TOKEN", "csrf-cookie");
    headers.append("Accept", "application/json");
    headers.append("Authorization", `Bearer ${userToken}`);

    var requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    fetch(
      "https://api.ichereta.com/api/client/setting/get-channel",
      requestOptions
    )
      .then((response) => {
        if (response.status === 401) {
          sessionStorage.clear();
          window.location.href = "/";
        }
        return response.json();
      })
      .then((result) => {
        setChannels({
          email: result.channels.email ? 1 : 0,
          sms: result.channels.sms ? 1 : 0,
          telegram: result.channels.telegram ? 1 : 0,
        });
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    if (userToken && !telegramTenderPage && !telegramDetailPage) {
      getAlertStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  // BOTTOM NAVIGATION //////////////////////////////////////////////////////////////////////////

  const [clickedBtn, setClickedBtn] = useState("");
  const currentPath = document.location.pathname;
  useEffect(() => {
    let btnId = "";
    if (currentPath === "/tenders") {
      btnId = "Btn1";
    } else if (currentPath === "/myfeed") {
      btnId = "Btn2";
    } else if (currentPath === "/saved") {
      btnId = "Btn3";
    }

    setClickedBtn(btnId);
  }, [currentPath]);

  // COMPANY DETAIL /////////////////////////////////////////////////////////////////////////////

  const [companyDetail, setCompanyDetail] = useState(null);

  // ////////////////////////////////////////////////////////////////////////////////////////////
  const [preferance, setPreferance] = useState(false);

  const getCategories = (filter) => {
    if (filter === "myfeed") {
      setIsFetchingFeedCategories(true);
    }

    var headers = new Headers();
    headers.append("X-XSRF-TOKEN", "csrf-cookie");
    headers.append("Accept", "application/json");
    headers.append("Authorization", `Bearer ${userToken}`);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: headers,
    };

    fetch(
      `https://api.ichereta.com/api/tender/categories?path=${filter}`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 401) {
          sessionStorage.clear();
          window.location.href = "/";
        }
        return response.json();
      })
      .then((result) => {
        if (filter === "") {
          setTendersCategoryLists(result);
        } else if (filter === "myfeed") {
          setFeedCategoryLists(result);
          setIsFetchingFeedCategories(false);
          result.length !== 0 ? setPreferance(false) : setPreferance(true);
        } else {
          setCategoryLists(result);
        }
      })
      .catch((error) => {
        setIsFetchingFeedCategories(false);
        console.log("error", error);
      });
  };

  const getLanguagesAndRegions = (filter) => {
    setIsFetchingLangAndReg(true);
    var headers = new Headers();
    headers.append("X-XSRF-TOKEN", "csrf-cookie");
    headers.append("Accept", "application/json");
    headers.append("Authorization", `Bearer ${userToken}`);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: headers,
    };

    fetch(
      `https://api.ichereta.com/api/tender/lang-reg?path=${filter}`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 401) {
          sessionStorage.clear();
          window.location.href = "/";
        }
        return response.json();
      })
      .then((result) => {
        if (filter === "") {
          setTendersLanguage(result.languages);
          setTendersRegions(result.regions);
        } else if (filter === "myfeed") {
          setIsFetchingLangAndReg(false);
          setFeedLanguage(result.languages);
          setFeedRegions(result.regions);
          result.languages.length !== 0 || result.regions.length !== 0
            ? setPreferance(false)
            : setPreferance(true);
        } else {
          setLanguage(result.languages);
          setRegions(result.regions);
        }
      })
      .catch((error) => {
        setIsFetchingLangAndReg(false);
        console.log("error", error);
      });
  };
  // Verification ////////////////////////////////////////////////////////////////////////////////
  const [verificationOtpIsSending, setVerificationOtpIsSending] = useState(
    false
  );
  const VerifyContact = (path, func) => {
    setVerificationOtpIsSending(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `"Bearer ${userToken}`);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://api.ichereta.com/api/auth/send-OTP?${path}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        func && func();

        setVerificationOtpIsSending(false);
      })
      .catch((error) => {
        setVerificationOtpIsSending(false);
        console.log("error", error);
      });
  };

  // SUBSCRIPTION //////////////////////////////////////////////////////////////////////////////

  const [isFetchingInvoice, setIsFetchingInvoice] = useState(false);

  const HandleSubscription = () => {
    const userToken = sessionStorage.getItem("token");
    const cbeToken = sessionStorage.getItem("cbeToken");
    console.log(userToken, "usertoken");

    setIsFetchingInvoice(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://api.ichereta.com/api/miniapp/cbe-encrypt?token=${cbeToken}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setIsFetchingInvoice(false);
        window.myJsChannel.postMessage(result.data);
      })
      .catch((error) => {
        setIsFetchingInvoice(false);
        console.log("error", error);
      });
  };
  // VALUES /////////////////////////////////////////////////////////////////////////////////////

  const value = {
    isVerified,
    setIsVerified,
    userInfo,
    setUserInfo,
    selectedPrice: selectedPlan,
    setSelectedPlan,
    token: token,
    setToken,
    finishedRegistration,
    setFinishedRegistration,
    popularCompanies: popularCompanies,
    setPopularCompanies,
    showSearchDarwer,
    setShowSearchDrawer,
    showDrawerHandler,
    popularCategories,
    setPopularCategories,
    tendersPopularCategories,
    setTendersPopularCategories,

    //popular Categories
    getPopularCategories,
    getingPopularCategories,
    popularCategoriesRef,

    // tenders fetching
    fetchData,
    tenders,
    selectedCategory,
    setSelectedCategory,
    isFetchingTenders,
    scrollCount,
    setScrollCount,
    fetchLimiter,

    // search filter
    categoryLists,
    setCategoryLists,
    regions,
    setRegions,
    language,
    setLanguage,
    SearchTenders,
    searchingTitle,
    setSearchingTitle,
    setSearchingRegionId,
    setSearchingLanguageId,
    setSearchingCategoryId,
    searchParams,
    setSearchParams,

    tendersCategoryLists,
    setTendersCategoryLists,
    tendersRegions,
    setTendersRegions,
    tendersLanguage,
    setTendersLanguage,
    feedCategoryLists,
    isFetchingFeedCategories,
    feedRegions,
    feedLanguage,
    getCategories,
    getLanguagesAndRegions,
    isFetchingLangAndReg,
    preferance,
    setPreferance,

    //
    searchIsClicked,
    setSearchIsClicked,
    selectedStatus,
    setSelectedStatus,

    // saved Alert
    savedCount,
    setSavedCount,

    //channels
    channels,
    setChannels,

    setTenders,
    // change page
    setApiPath,

    //bottom navigation
    clickedBtn,
    setClickedBtn,
    //tender detail
    showTenderDetail,
    setShowTenderDetail,
    prevRoute,
    setPrevRoute,
    scrollCoordinates,
    setScrollCoordinates,

    setShouldLoadTenders,

    //company details
    companyDetail,
    setCompanyDetail,

    //verify
    VerifyContact,
    verificationOtpIsSending,

    //subscription
    HandleSubscription,
    isFetchingInvoice,

    //telegram
    setTelegramApiPath,

    // CBE Birr

    cbePhone,
    setCbePhone,
    cbeToken,
    setCbeToken,
  };

  return <context.Provider value={value}>{props.children}</context.Provider>;
};

export default TendersContextProvider;
