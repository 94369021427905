import { useEffect, useContext, useState } from "react";
import context from "../../store/context";

import MultiSelect from "../MultiSelect";
import AuthenticationButton from "../Buttons/AuthenticationButton";

const PreferanceForm = ({ onResponse, showHeader }) => {
  const {
    tendersRegions,
    tendersLanguage,
    tendersCategoryLists,
    getCategories,
    feedRegions,
    feedLanguage,
    feedCategoryLists,
    isFetchingFeedCategories,
    isFetchingLangAndReg,
    getLanguagesAndRegions,
    setPreferance,
  } = useContext(context);

  useEffect(() => {
    getCategories("myfeed");
    getLanguagesAndRegions("myfeed");
    if (tendersCategoryLists.length === 0) {
      getCategories("");
      getLanguagesAndRegions("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [languageValue, setLanguageValue] = useState();
  const [regionsValue, setRegionsValue] = useState();
  const [categoriesValue, setCategoriesValue] = useState();
  const [isSending, setIsSending] = useState(false);

  const getLanguageValue = (value) => {
    const ids = value.map((item) => item.id);
    setLanguageValue(ids);
  };
  const getRegionsValue = (value) => {
    const ids = value.map((item) => item.id);
    setRegionsValue(ids);
  };
  const getCategoriesValue = (value) => {
    const ids = value.map((item) => item.id);
    setCategoriesValue(ids);
  };

  const userToken = sessionStorage.getItem("token");

  const updatePreferance = () => {
    setIsSending(true);
    var headers = new Headers();
    headers.append("Authorization", `Bearer ${userToken}`);
    headers.append("Content-Type", "application/json");
    headers.append("X-XSRF-TOKEN", "csrf-cookie");
    headers.append("Accept", "application/json");

    var raw = JSON.stringify({
      selectedLanguages: languageValue,
      selectedRegions: regionsValue,
      selectedCategories: categoriesValue,
    });

    var requestOptions = {
      method: "POST",
      headers: headers,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api.ichereta.com/api/client/setting/save-prefered",
      requestOptions
    )
      .then((response) => {
        if (response.status === 401) {
          sessionStorage.clear();
          window.location.href = "/";
        }
        return response.json();
      })
      .then((result) => {
        setIsSending(false);

        setPreferance(
          languageValue.length === 0 &&
            regionsValue.length === 0 &&
            categoriesValue.length === 0
        );
        onResponse && onResponse();
      })
      .catch((error) => {
        setIsSending(false);
        console.log("error", error);
      });
  };

  return (
    <div className="w-full  text-[#3d5984]  flex flex-col items-center text-center space-y-5">
      {showHeader && (
        <h1 className="text-xl font-bold">Update Your Preferences.</h1>
      )}
      {showHeader && (
        <p className="text-sm">
          TO ENSURE YOU RECEIVE THE MOST RELEVANT TENDERS, UPDATE YOUR
          PREFERENCE NOW.
        </p>
      )}
      <div className="w-full flex justify-center">
        {tendersLanguage.length === 0 || isFetchingLangAndReg ? (
          <div className="border border-[#664bc7] rounded text-sm relative w-full  h-10 flex items-center justify-center">
            <h1>Fetching...</h1>
          </div>
        ) : (
          <MultiSelect
            onValueSelect={getLanguageValue}
            selecteds={feedLanguage}
            fullist={tendersLanguage}
            placeholder="Languages"
            id={"languagesMultiSelect"}
            name={"languagesMultiSelect"}
          />
        )}
      </div>
      <div className="w-full flex justify-center">
        {tendersRegions.length === 0 || isFetchingLangAndReg ? (
          <div className="border border-[#664bc7] rounded text-sm relative w-full  h-10 flex items-center justify-center">
            <h1>Fetching...</h1>
          </div>
        ) : (
          <MultiSelect
            onValueSelect={getRegionsValue}
            selecteds={feedRegions}
            fullist={tendersRegions}
            placeholder="Regions"
            id={"regionsMultiSelect"}
            name={"regionsMultiSelect"}
          />
        )}
      </div>
      <div className="w-full flex justify-center">
        {tendersCategoryLists.length === 0 || isFetchingFeedCategories ? (
          <div className="border border-[#664bc7] rounded text-sm relative w-full  h-10 flex items-center justify-center">
            <h1>Fetching...</h1>
          </div>
        ) : (
          <MultiSelect
            onValueSelect={getCategoriesValue}
            selecteds={feedCategoryLists}
            fullist={tendersCategoryLists}
            placeholder="Categories"
            id={"categoriesMultiSelect"}
            name={"categoriesMultiSelect"}
          />
        )}
      </div>
      <AuthenticationButton
        process={isSending}
        onClick={updatePreferance}
        name={"Save Changes"}
      />
    </div>
  );
};

export default PreferanceForm;
