import { useRef, useEffect, useContext, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import context from "../store/context";

import TendersLayout from "../pages/Tender/TendersLayout";
import DateConverter from "../components/DateConverter";
import TimeDifference from "../components/TimeDifference";
// import SaveUnsave from "../components/Buttons/SaveUnsave";
import TenderDetailShimmer from "../pages/Tender/TenderDetailShimmer";
import DetailHTML from "../pages/Tender/DetailHTML";
import TenderContentPlaceholder from "../components/cards/TenderContentPlaceholder";
import CompanyInfo from "../components/cards/CompanyInfo";
import CompanyInfoShimmer from "../components/cards/CompanyInfoShimmer";

// icons
import TagIcon from "../components/icons/Tag";

const TenderDetailTelegram = () => {
  const { setShowTenderDetail, prevRoute, setShouldLoadTenders } = useContext(
    context
  );

  const [isFetching, setIsFetching] = useState(false);
  const [response, setResponse] = useState({});
  const params = useParams();
  const navigate = useNavigate();

  const userToken = true;

  useEffect(() => {
    var headers = new Headers();
    headers.append("X-XSRF-TOKEN", "csrf-cookie");
    headers.append("Accept", "application/json");
    headers.append("Authorization", `Bearer ${userToken}`);

    var requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };
    setIsFetching(true);

    // const auth_data =
    //   "query_id%3DAAFnWIsXAAAAAGdYixeizTjn%26user%3D%257B%2522id%2522%253A395008103%252C%2522first_name%2522%253A%2522Biruk%2520K.%2522%252C%2522last_name%2522%253A%2522%2522%252C%2522username%2522%253A%2522K_Biruk%2522%252C%2522language_code%2522%253A%2522en%2522%252C%2522is_premium%2522%253Atrue%252C%2522allows_write_to_pm%2522%253Atrue%257D%26auth_date%3D1690538487%26hash%3D8c4f1fa8320e74cb08c4322d97aae9f017ab412dd1405cd01302bc4c8c67c7cb";

    const auth_data = encodeURIComponent(window.Telegram.WebApp.initData);
    const telegramTenderId = window.Telegram.WebApp.initDataUnsafe.start_param;

    console.log(telegramTenderId, "id");

    fetch(
      `https://api.ichereta.com/api/telegram/tender/${
        params.telegramTenderId === "app"
          ? telegramTenderId
          : params.telegramTenderId
      }?data_check_string=${auth_data}`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 401) {
          sessionStorage.clear();
          window.location.href = "/";
        }
        return response.json();
      })
      .then((result) => {
        setResponse(result.data);
        setIsFetching(false);
        document.title = result.data.title;
        // console.log(result.data.content);
      })
      .catch((error) => {
        setIsFetching(false);
        console.log("error", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const currentTime = new Date();
  const isBackPressedRef = useRef(false);

  const setIsBackPressed = (value) => {
    isBackPressedRef.current = value;
  };

  useEffect(() => {
    setShouldLoadTenders(false);

    return () => {
      if (!isBackPressedRef.current) {
        setShouldLoadTenders(true);
      }
    };
  }, [setShouldLoadTenders]);

  const handleGoBack = () => {
    setIsBackPressed(true);
    setShowTenderDetail(true);
    navigate(prevRoute);
  };

  // const redirect = () => {
  //   navigate("/");
  // };

  const notAUser = "Login or Subscribe";
  window.Telegram.WebApp.BackButton.show();
  window.Telegram.WebApp.BackButton.onClick(handleGoBack);

  return (
    <TendersLayout showSearchSidebar={false} redirect={true} filter={""}>
      <div className="w-full h-full overflow-y-auto flex flex-col items-start p-2 sm:p-5 space-y-8 text-[#3d5984]">
        {isFetching ? (
          <TenderDetailShimmer />
        ) : (
          <div className="flex flex-col items-start space-y-2 w-full">
            {/* <div className="flex w-full justify-between">
              <button
                onClick={handleGoBack}
                className="bg-white pl-2 pr-5 rounded hover:bg-blue-50/50 flex items-center space-x-2 shadow text-xs font-bold"
              >
                <BackIcon styles="w-5 h-5" /> <span>Back</span>
              </button>
              <div className="flex items-center space-x-2 ">
                <SaveUnsave
                  navigate={redirect}
                  isSavedDB={response.is_saved}
                  id={response.id}
                />
              </div>
            </div> */}
            <div className="bg-white w-full rounded-md p-3 sm:p-4 md:p-5 shadow">
              <div className="border-b border-b-gray-200/80 pb-2 text-sm sm:text-base font-semibold text-start w-full">
                <h1>{response.title}</h1>
              </div>
              <div className="flex flex-col space-y-2 text-xs sm:text-sm items-start pt-2 w-full overflow-hidden">
                <div className="flex items-start space-x-2">
                  <h1 className="font-bold min-w-max">Bid Closing Date: </h1>
                  {userToken ? (
                    response.alternative_close_date ? (
                      <span className="text-start">
                        {response.alternative_close_date}{" "}
                      </span>
                    ) : response.bid_close_date ? (
                      <DateConverter dateString={response.bid_close_date} />
                    ) : (
                      ""
                    )
                  ) : (
                    <span>{notAUser}</span>
                  )}
                </div>
                <div className="flex items-start space-x-2">
                  <h1 className="font-bold min-w-max">Bid Opening Date: </h1>

                  {userToken ? (
                    response.alternative_open_date ? (
                      <span className="text-start">
                        {response.alternative_open_date}
                      </span>
                    ) : response.bid_open_date ? (
                      <DateConverter dateString={response.bid_open_date} />
                    ) : (
                      ""
                    )
                  ) : (
                    <span>{notAUser}</span>
                  )}
                </div>

                <div className="flex items-start space-x-2">
                  <h1 className="font-bold min-w-max">Published On: </h1>
                  <div className="flex flex-wrap">
                    {userToken ? (
                      response.tender_sources ? (
                        response.tender_sources.map((publisher, index) => {
                          return (
                            <div
                              className="text-start flex items-start space-x-2"
                              key={publisher.source.id}
                            >
                              {index !== 0 && <span>,</span>}
                              <span className="min-w-max">
                                {publisher.source.name}
                              </span>
                              <div className="flex">
                                <span>{`(`}</span>
                                <DateConverter
                                  dateString={publisher.published_at}
                                />
                                <span>{`)`}</span>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        ""
                      )
                    ) : (
                      <span>{notAUser}</span>
                    )}
                  </div>
                </div>
                <div className="flex items-center space-x-2">
                  <span className="font-bold">Posted: </span>
                  <span>
                    <TimeDifference
                      currentTime={currentTime}
                      dateString={response.created_at}
                    />
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  <span className="font-bold">Bid Document Price: </span>
                  <span>
                    {userToken
                      ? response.bid_doc_price
                        ? `${response.bid_doc_price}`
                        : ""
                      : notAUser}
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  <span className="font-bold">Bid Bond Price: </span>
                  <span>
                    {userToken
                      ? response.bid_bond_price
                        ? `${response.bid_bond_price}`
                        : ""
                      : notAUser}
                  </span>
                </div>
                <div className="flex items-start space-x-2">
                  <span className="font-bold">Bidding: </span>

                  <div
                    className={`flex items-center font-semibold space-x-2 ${
                      Number(response.status) === 1
                        ? "text-green-600 "
                        : "text-red-500 "
                    }  bg-opacity-40 px-2 py-1 text-xs`}
                  >
                    {Number(response.status) === 1 ? (
                      <span>OPEN</span>
                    ) : (
                      <span>CLOSED</span>
                    )}
                  </div>
                </div>

                <div className="py-5 border-y border-slate-200 flex break-words w-full overflow-x-auto text-sm">
                  {response.content ? (
                    <DetailHTML htmlString={response.content} />
                  ) : (
                    <TenderContentPlaceholder />
                  )}
                </div>

                <div className="flex flex-wrap items-center pt-2">
                  {response.tender_categories &&
                    response.tender_categories.map((category, index) => {
                      return (
                        <div
                          key={`${category.category_id}-${index}`}
                          className="flex items-center space-x-2 border border-[#3d5984]/20 text-[#3d5984]/80 px-3 py-1 rounded mr-3 mb-2.5"
                        >
                          <TagIcon styles="w-4 h-4" />
                          <span>{category.category.name}</span>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        )}

        {isFetching ? (
          <CompanyInfoShimmer />
        ) : (
          response.company && (
            <CompanyInfo
              src={response.company.logo}
              name={response.company.name}
              id={response.company.id}
            />
          )
        )}
      </div>
    </TendersLayout>
  );
};

export default TenderDetailTelegram;
