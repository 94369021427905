import "../../shimmer.css";

const TenderDetailShimmer = () => {
  return (
    <div className="flex flex-col w-full space-y-2">
      <div className="flex w-full justify-between">
        <div className="bg-white px-2 rounded hover:bg-blue-50 h-[27px] w-[100px] animate-pulse-gradient "></div>
        <div className="bg-white px-2 rounded hover:bg-blue-50 h-[27px] w-[100px] animate-pulse-gradient "></div>
      </div>
      <div className="w-full flex flex-col bg-white rounded-md  p-3 sm:p-4 md:p-5 space-y-2 shadow text-[#3d5984]">
        <div className="flex flex-col items-start pb-2 border-b border-b-gray-200/80">
          <div className="mb-2 text-sm sm:text-base font-semibold h-2.5 md:h-3 w-full rounded-full animate-pulse-gradient"></div>
          <div className=" mb-2 text-sm sm:text-base font-semibold h-2.5 md:h-3 w-full md:w-[70%] rounded-full animate-pulse-gradient"></div>
          <div className=" mb-2 text-sm sm:text-base font-semibold  h-2.5 md:h-3 w-[60%] rounded-full animate-pulse-gradient md:hidden"></div>
        </div>
        <div className="flex flex-col space-y-2.5 md:space-y-3 py-2 text-xs sm:text-sm">
          <div className="flex items-center space-x-3">
            <span className="font-bold h-2 md:h-2.5 w-[30%] max-w-[120px] rounded-full animate-pulse-gradient"></span>
            <span className="font-bold h-2 md:h-2.5 w-[40%] max-w-[200px] rounded-full animate-pulse-gradient"></span>
          </div>
          <div className="flex items-center space-x-3">
            <span className="font-bold h-2 md:h-2.5 w-[35%] max-w-[130px] rounded-full animate-pulse-gradient"></span>
            <span className="font-bold h-2 md:h-2.5 w-[40%] max-w-[200px] rounded-full animate-pulse-gradient"></span>
          </div>
          <div className="flex items-center space-x-3">
            <span className="font-bold h-2 md:h-2.5 w-[20%] max-w-[100px] rounded-full animate-pulse-gradient"></span>
            <span className="font-bold h-2 md:h-2.5 w-[30%] max-w-[150px] rounded-full animate-pulse-gradient"></span>
          </div>
          <div className="flex items-center space-x-3 ">
            <span className="font-bold h-2 md:h-2.5 w-[15%]  max-w-[70px] rounded-full animate-pulse-gradient"></span>
            <span className="font-bold h-2 md:h-2.5 w-[25%]  max-w-[120px] rounded-full animate-pulse-gradient"></span>
          </div>
          <div className="flex items-center space-x-3">
            <span className="font-bold h-2 md:h-2.5 w-[40%] max-w-[150px] rounded-full animate-pulse-gradient"></span>
            <span className="font-bold h-2 md:h-2.5 w-[37%] max-w-[170px] rounded-full animate-pulse-gradient"></span>
          </div>
          <div className="flex items-center space-x-3">
            <span className="font-bold h-2 md:h-2.5 w-[30%] max-w-[120px] rounded-full animate-pulse-gradient"></span>
            <span className="font-bold h-2 md:h-2.5 w-[35%] max-w-[150px] rounded-full animate-pulse-gradient"></span>
          </div>
          <div className="flex items-center space-x-3 ">
            <span className="font-bold h-2 md:h-2.5 w-[20%]  max-w-[100px] rounded-full animate-pulse-gradient"></span>
            <span className="font-bold h-2 md:h-2.5 w-[30%]  max-w-[120px] rounded-full animate-pulse-gradient"></span>
          </div>
        </div>
        <div className="flex flex-wrap items-center justify-between border-y border-t-gray-200/80 py-3">
          <div className="w-full h-[350px]  animate-pulse-gradient rounded"></div>
        </div>
        <div className="text-xs sm:text-sm flex flex-wrap items-center pt-2">
          <div className="w-[70px] md:w-[100px] h-[25px] md:h-[30px] rounded animate-pulse-gradient mr-3 mb-2.5"></div>
          <div className="w-[70px] md:w-[100px] h-[25px] md:h-[30px] rounded animate-pulse-gradient mr-3 mb-2.5"></div>
          <div className="w-[70px] md:w-[100px] h-[25px] md:h-[30px] rounded animate-pulse-gradient mr-3 mb-2.5"></div>
        </div>
      </div>
    </div>
  );
};

export default TenderDetailShimmer;
