import { useState, useContext, useEffect } from "react";
import context from "../store/context";

import { Link } from "react-router-dom";
import OtpInput from "../components/OtpInput";
import AuthenticationButton from "../components/Buttons/AuthenticationButton";
// import MaskedContact from "../components/MaskedContact";

import LogoImg from "../assets/logo.svg";

import BackIcon from "../components/icons/Back";
import CheckIcon from "../components/icons/Check";

const VerificationLayout = ({ contact, type, path, src }) => {
  const userToken = sessionStorage.getItem("token");

  const [otpValues, setOtpValues] = useState([]);

  const [hasError, setHasError] = useState(false);
  const [errormessage, setErrorMessage] = useState("");

  const [contactIsVerified, setContactIsVerified] = useState(false);

  const handleOtpChange = (otp) => {
    setOtpValues(otp);
  };

  const {
    isVerified,
    setIsVerified,
    VerifyContact,
    verificationOtpIsSending,
  } = useContext(context);

  const [isFetching, setIsFetching] = useState(false);

  // resending
  const [totalSeconds, setTotalSeconds] = useState(60);
  const [buttonEnabled, setButtonEnabled] = useState(false);

  // reset timer for resending
  const resetTimer = () => {
    setButtonEnabled(false);
    setTotalSeconds(60);
  };

  useEffect(() => {
    if (totalSeconds > 0) {
      const timer = setInterval(
        () => setTotalSeconds((prevSeconds) => prevSeconds - 1),
        1000
      );
      return () => clearInterval(timer);
    } else {
      setButtonEnabled(true);
    }
  }, [totalSeconds]);

  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  const formattedTime = `${minutes}:${seconds.toString().padStart(2, "0")}`;

  const verificationFinalStep = () => {
    const combinedNumber = parseInt(otpValues.join(""), 10);

    if (combinedNumber.toString().length === 4) {
      setIsFetching(true);
      setHasError(false);
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `https://api.ichereta.com/api/auth/verify-contact?${path}=${contact}&code=${combinedNumber}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status) {
            setContactIsVerified(true);
            if (path === "email") {
              setIsVerified({
                ...isVerified,
                email: true,
              });
            } else {
              setIsVerified({
                ...isVerified,
                phone: true,
              });
            }
          }
          setIsFetching(false);

          if (result.status === false) {
            setHasError(true);
            setErrorMessage(result.message);
          }
        })
        .catch((error) => {
          setIsFetching(false);
          console.log("error", error);
        });
    }
  };

  return (
    <div className="w-[90%] max-w-[500px] flex flex-col items-center space-y-10 text-[#3d5984]">
      <img className="w-32 sm:w-40" src={LogoImg} alt="ichereta logo" />
      {contactIsVerified ? (
        <div className="flex flex-col items-center space-y-5 sm:space-y-8  shadow-md w-[95%] text-center rounded-md py-8 sm:py-16 md:py-20 border border-slate-100 text-green-500  bg-white">
          <CheckIcon styles="w-16 h-16 sm:w-20 sm:h-20" />
          <div className="flex flex-col items-center space-y-1 px-5">
            <h1 className="text-xl sm:text-3xl font-semibold text-gray-500">
              Verified <span className="text-2xl sm:text-4xl">!</span>
            </h1>

            <p className="text-sm text-gray-500">
              You have successful verified your {type}
            </p>
          </div>

          <Link
            to="/tenders"
            className="flex items-center space-x-2 px-8 py-2 rounded bg-[#664bc7] text-white mt-4 text-sm"
          >
            <BackIcon styles="w-6 h-6" /> <span>Back to Tenders</span>
          </Link>
        </div>
      ) : (
        <div className="flex flex-col items-center space-y-7 md:space-y-10 shadow-md w-full rounded-md px-5 pb-5 border border-slate-100  bg-white">
          <div className="text-lg sm:text-xl font-semibold w-full border-b border-slate-200 text-center py-3.5 sm:py-4 md:py-5">
            <h1>
              Verify <span>{type}</span>
            </h1>
          </div>
          <h1 className="text-sm md:text-base flex flex-wrap items-center text-center">
            <p className="mr-2">Enter the OTP sent to</p>
            <span className="font-bold">
              {/* <MaskedContact contact={contact} /> */}
              {contact}
            </span>
          </h1>
          <div className="flex flex-col items-center w-full justify-center">
            <OtpInput length={4} onOtpChange={handleOtpChange} />
            <div className="h-3 flex text-start pt-3">
              {hasError && (
                <h1 className="text-red-500 text-sm">{errormessage}</h1>
              )}
            </div>
          </div>
          <div className="flex items-center space-x-2 text-sm md:text-base">
            <p>Haven't Recieved OTP ?</p>
            <div className="flex items-center space-x-2">
              {totalSeconds > 0 && (
                <h1 className="text-gray-600">{formattedTime}</h1>
              )}
              <button
                onClick={() => {
                  VerifyContact(`${path}=${contact}&for=${path}`, resetTimer);
                }}
                disabled={
                  buttonEnabled && verificationOtpIsSending
                    ? buttonEnabled
                    : !buttonEnabled
                }
                className={`${
                  totalSeconds > 0 ? "text-gray-400" : " text-[#664bc7]"
                } text-sm sm:text-base font-medium`}
              >
                Resend
              </button>
              {verificationOtpIsSending && (
                <div className="w-5 h-5 border-t border-indigo-600 rounded-full animate-spin"></div>
              )}
            </div>
          </div>
          <AuthenticationButton
            process={isFetching}
            onClick={verificationFinalStep}
            name="Verify"
          />
        </div>
      )}
      {!contactIsVerified && (
        <Link to="/tenders" className="flex items-center space-x-2">
          <BackIcon styles="w-6 h-6" /> <span>Back to Tenders</span>
        </Link>
      )}
    </div>
  );
};

export default VerificationLayout;
