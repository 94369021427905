import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";


// components
import context from "../../store/context";

import Input from "../Input";
import AuthenticationButton from "../Buttons/AuthenticationButton";
import PriceSelector from "../PriceSelector";

// hooks
// import useFetch from "../../hooks/use-fetch";
import useInput from "../../hooks/use-Input";

// icons
import PersonIcon from "../icons/Person";

const RegistrationStepOne = (props) => {
  const { setToken } = useContext(context);
  const navigate = useNavigate();


  const [isSending, setIsSending] = useState(false);
  // const [hasError, setHasError] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");

  const namesValidator = (enteredValue) => {
    return enteredValue.trim().length !== 0;
  };

  const {
    enteredValue: fNameValue,
    hasError: fNameHasError,
    valueChangeHandler: fNameValueChangeHandler,
    inputBlurHandler: fNameInputBlurHandler,
    isTouched: fNameIsTouched,
    isValid: fNameIsValid,
    reset: fNameReset,
  } = useInput(namesValidator, props.inputData?.fName || "");

  const {
    enteredValue: lNameValue,
    hasError: lNameHasError,
    valueChangeHandler: lNameValueChangeHandler,
    inputBlurHandler: lNameInputBlurHandler,
    isTouched: lNameIsTouched,
    isValid: lNameIsValid,
    reset: lNameReset,
  } = useInput(namesValidator, props.inputData?.lName || "");

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const cbeAutToken = sessionStorage.getItem("cbeToken");

  const register = () => {
    if (fNameIsValid && lNameIsValid) {
      setIsSending(true);
      // setHasError(false);

      var requestOptions = {
        method: "POST",
        redirect: "follow",
      };

      fetch(
        `https://api.ichereta.com/api/miniapp/cbe-register?token=${cbeAutToken}&first_name=${fNameValue}&last_name=${lNameValue}&plan_id=${selectedOption}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.status === true) {
            props.onSubmit({
              fName: fNameValue,
              amount: result.amount,
              invoiceNumber: result.invoice_number,
            });
            props.onClick();
            // sessionStorage.setItem("token", result.token); // Save token to sessionStorage
            sessionStorage.setItem("token", result.token);
            setToken(result.token);
            navigate("/tenders");
          } else {
            // setErrorMessage(result.errors.phone);
            // setHasError(true);
          }
          setIsSending(false);
        })
        .catch((error) => {
          setIsSending(false);
          // setHasError(true);
          console.log("error", error);
        });
    }
  };

  const [selectedOption, setSelectedOption] = useState("3");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.id);
  };

  return (
    <div className={` flex flex-col w-full space-y-3 p-[3%]`}>
      <div className="w-full flex flex-col space-y-3 sm:space-y-0 sm:flex-row sm:space-x-5">
        <Input
          onChange={fNameValueChangeHandler}
          onBlur={fNameInputBlurHandler}
          hasError={fNameHasError}
          isValid={fNameIsValid}
          isTouched={fNameIsTouched}
          enteredValue={fNameValue}
          reset={fNameReset}
          type="text"
          id="firstName"
          lable="ስም"
        >
          <PersonIcon styles="w-6 h-6 mx-3 text-slate-400" />
        </Input>
        <Input
          onChange={lNameValueChangeHandler}
          onBlur={lNameInputBlurHandler}
          hasError={lNameHasError}
          isValid={lNameIsValid}
          isTouched={lNameIsTouched}
          enteredValue={lNameValue}
          reset={lNameReset}
          type="text"
          id="LastName"
          lable="የአባት ስም"
        >
          <PersonIcon styles="w-6 h-6 mx-3 text-slate-400" />
        </Input>
      </div>
      <div className="flex flex-col pb-3">
        <span className="font-bold text-base text-gray-700">
          የአባልነት ጥቅል ይምረጡ
        </span>
        <span className="text-xs sm:text-sm text-green-600">
          የአመታዊ ጥቅል በመጠቀም አገልግሎቱን በቅናሽ ያግኙ ።
        </span>
      </div>
      <div className="flex grid grid-cols-3 gap-x-3 itmes-center justify-center pb-3">
        <PriceSelector
          id="1"
          onChange={handleOptionChange}
          duration="3 Months"
          active={selectedOption}
          price="980 birr"
        />
        <PriceSelector
          id="3"
          onChange={handleOptionChange}
          duration="1 Year"
          active={selectedOption}
          price="2360 birr"
        />
        <PriceSelector
          id="2"
          onChange={handleOptionChange}
          duration="6 Months"
          active={selectedOption}
          price="1480 birr"
        />
      </div>

      <AuthenticationButton
        onClick={register}
        process={isSending}
        name={
          <div className="flex items-center w-full justify-center space-x-2">
            <span className="font-bold">ምዝገባዎን ያጠናቁ</span>
          </div>
        }
      />
    </div>
  );
};

export default RegistrationStepOne;
