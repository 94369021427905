import { useEffect, useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";

// context
import context from "../store/context";

// components
import NavigationBar from "../components/UI/NavigationBar";
import BottomNav from "../components/UI/BottomNav";
import Footer from "../components/UI/Footer";
import SearchSidebarPhone from "../components/section/SearchSidebarPhone";

const Rootlayout = () => {
  const location = useLocation();
  const hideNavAndFooter =
    location.pathname.startsWith("/signup") ||
    location.pathname.startsWith("/forgot-password") ||
    location.pathname.startsWith("/verification/") ||
    location.pathname.startsWith("/invoice/") ||
    location.pathname.startsWith("/payments") ||
    location.pathname === "/";

  const hideFooter =
    location.pathname.startsWith("/tender") ||
    location.pathname.startsWith("/myfeed") ||
    location.pathname.startsWith("/saved") ||
    location.pathname.startsWith("/myaccount") ||
    location.pathname.startsWith("/billing") ||
    location.pathname.startsWith("/settings") ||
    location.pathname.startsWith("/company");

  const token = sessionStorage.getItem("token");

  const { showSearchDarwer, showDrawerHandler } = useContext(context);

  const hideBottomNav =
    location.pathname.startsWith("/verification") ||
    location.pathname.startsWith("/myaccount") ||
    location.pathname.startsWith("/billing") ||
    location.pathname.startsWith("/settings") ||
    location.pathname.startsWith("/invoice") ||
    location.pathname.startsWith("/payments");

  const telegramPage =
    location.pathname.startsWith("/latest-tenders/telegram") ||
    location.pathname.startsWith("/tender/telegram") ||
    location.pathname.startsWith("/myfeed/telegram");

  const pathname = location.pathname;
  let pageTitle = pathname.replace(/^\/|\/$/g, "");
  pageTitle = pageTitle.charAt(0).toUpperCase() + pageTitle.slice(1);

  useEffect(() => {
    if (pathname === "/") {
      document.title = "iChereta";
    } else if (
      !pathname.startsWith("/company") &&
      !pathname.startsWith("/tender/")
    ) {
      document.title = pageTitle;
    } else if (pathname.startsWith("/verification")) {
      document.title = "Verification";
    }
  }, [pageTitle, pathname]);

  const tenderFilter = location.pathname.startsWith("/tender");
  const feedFilter = location.pathname.startsWith("/myfeed");

  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex flex-col items-center  min-h-screen w-full">
        {showSearchDarwer && !telegramPage && (
          <SearchSidebarPhone
            onClick={showDrawerHandler}
            filter={tenderFilter ? "" : feedFilter ? "myfeed" : "saved"}
          />
        )}
        {!hideNavAndFooter && !telegramPage && <NavigationBar />}
        <main
          className={`flex flex-col flex-grow items-center w-full ${
            !hideNavAndFooter &&
            !telegramPage &&
            " mt-[61px] sm:mt-[73px] md:mt-[69px] lg:mt-[79px]"
          }`}
        >
          <Outlet />
        </main>
      </div>

      {!hideNavAndFooter && !hideFooter && !telegramPage && <Footer />}
      {token && !showSearchDarwer && !hideBottomNav && !telegramPage && (
        <BottomNav />
      )}
    </div>
  );
};

export default Rootlayout;
