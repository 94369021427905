import { useState, useEffect, useRef, useContext } from "react";
import context from "../store/context";

import { Link } from "react-router-dom";

import BellIcon from "./icons/Bell";

const Notification = ({ show }) => {
  const [showNotification, setShowNotification] = useState(false);
  const {
    isVerified,
    VerifyContact,
    userInfo,
    HandleSubscription,
    isFetchingInvoice,
  } = useContext(context);

  const notificationToggleHandler = () => {
    setShowNotification(!showNotification);
  };

  const notificationRef = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setShowNotification(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={notificationRef}
      className={`cursor-pointer text-[#664bc7] hover:text-orange-700 transition-colors duration-200  relative ${show}`}
    >
      <button
        className="relative bg-blue-100/50 hover:bg-blue-100 transition-colors duration-200  p-1.5 rounded-full flex items-center justify-center"
        onClick={notificationToggleHandler}
      >
        <div className="w-2.5 h-2.5 rounded-full bg-red-500 absolute -right-1 top-0"></div>
        <div className="w-2.5 h-2.5 rounded-full bg-red-500 absolute -right-1 top-0 animate-ping"></div>
        <BellIcon styles="w-5 h-5" />
      </button>

      {showNotification && (
        <div className="absolute top-full mt-2 right-0 w-[300px]  bg-white shadow border border-slate-200 rounded flex flex-col items-start text-[#3d5984] overflow-y-auto max-h-[250px]">
          <div className="py-2 px-5 font-semibold w-full space-y-2 sticky top-0 bg-white border-b border-slate-200">
            <h1>Notification</h1>
          </div>

          <div className="flex flex-col w-full">
            {userInfo.isActive !== 1 && (
              <div className="w-full bg-white hover:bg-blue-50/30 flex flex-col items-start p-5 space-y-2 text-sm">
                <h1 className="font-bold">
                  የiChereta አካውንቶ አክቲቭ አይደለም ፣ ጨረታዎችን ለመመልከት ክፍያ ይፍጽሙ!
                </h1>
                <div className="w-full">
                  <button
                    onClick={HandleSubscription}
                    className={`py-1.5 animate-button text-white shadow w-[150px] rounded relative text-center mx-auto text-lg font-bold `}
                  >
                    <span className="text-semibold">ይክፈሉ!</span>
                    {isFetchingInvoice && (
                      <div className="absolute top-0 left-0 w-full h-full rounded bg-black/40 flex items-center justify-center">
                        <div className=" animate-spin border-t border-white w-5 h-5 rounded-full"></div>
                      </div>
                    )}
                  </button>
                </div>
              </div>
            )}

            {!isVerified.email && userInfo.email && (
              <div className="px-5 w-full">
                <hr className="border-t border-slate-200 w-full" />
              </div>
            )}

            {!isVerified.email && userInfo.email && (
              <div className="w-full bg-white hover:bg-blue-50/30 flex flex-col items-start p-5 space-y-2 text-sm">
                <h1>Verify Your Email.</h1>
                <Link
                  to="verification/email"
                  onClick={() => {
                    VerifyContact(`email=${userInfo.email}&for=email`);
                  }}
                  className="bg-orange-400 hover:bg-orange-500 text-white font-medium px-5 py-1 rounded"
                >
                  Verify
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Notification;
