import { useState } from "react";

const useInput = (validateValue, initialValue = "") => {
  const [enteredValue, setEnteredValue] = useState(initialValue);
  const [isTouched, setIsTouched] = useState(false);

  const isValid = validateValue(enteredValue);

  const hasError = !isValid && isTouched;

  const valueChangeHandler = (event) => {
    setEnteredValue(event.target.value);
  };

  const inputBlurHandler = () => {
    setIsTouched(true);
  };

  const reset = () => {
    setEnteredValue(initialValue || "");
    setIsTouched(false);
  };

  return {
    enteredValue,
    hasError,
    valueChangeHandler,
    inputBlurHandler,
    isTouched,
    isValid,
    reset,
  };
};

export default useInput;
