import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useLocation } from "react-router-dom";

// context
import context from "../../store/context";

//components
import Navigator from "../Navigator";
import AccountToolTip from "./AccountTooltip";
import SavedCount from "../SavedCount";
import Notification from "../Notification";

//images
import MainLogo from "../../assets/logowitht.svg";

//icons
import BurggerMenu from "../icons/Burgger";
import SearchIcon from "../icons/Search";

const NavigationBar = () => {
  const location = useLocation();

  // context
  const {
    token,
    showDrawerHandler,
    showSearchDarwer,
    isVerified,
    userInfo,
  } = useContext(context);

  // navigation menu
  const [menuExpanded, setMenuExpanded] = useState(false);

  const myContentRef = useRef(null);
  const showSearchBtn = location.pathname.startsWith("/tenders");

  const burggerMenuHandler = () => {
    setMenuExpanded((isExpanded) => {
      return !isExpanded;
    });
  };

  const colapseMenu = () => {
    setMenuExpanded(false);
  };
  useEffect(() => {
    const myContent = myContentRef.current;

    if (menuExpanded) {
      myContent.style.maxHeight = `${myContent.scrollHeight}px`;
      myContent.style.opacity = 1;
    } else {
      if (window.innerWidth < 768) {
        myContent.style.maxHeight = `${0}px`;
        myContent.style.opacity = 0;
      }
    }
  }, [menuExpanded]);

  return (
    <header
      className={`w-full flex justify-center md:items-center fixed z-[100]
      top-0 left-0  border-b border-b-slate-200 backdrop-blur-sm bg-white/90  ${
        !menuExpanded && "h-[61px] sm:h-[73px] md:h-[69px] lg:h-[79px]"
      }`}
    >
      <div className="w-[90%] flex  flex-wrap justify-between items-center   lg:max-w-[1500px] py-3.5  sm:py-5 max-h-auto">
        <div className=" flex items-center justify-between w-full md:w-auto">
          <div className="flex justify-between items-center w-full relative">
            <button onClick={colapseMenu} className="z-40">
              <Link to={"/tenders"}>
                <div>
                  <img
                    className="w-[104px] lg:w-32"
                    src={MainLogo}
                    alt="mainlogo"
                  />
                </div>
              </Link>
            </button>
            <div className="flex items-center justify-end md:hidden absolute right-0 w-full"></div>
          </div>
          {token && (
            <div className="flex items-center space-x-5">
              {(userInfo.isActive === 0 ||
                (!isVerified.email && userInfo.email)) && (
                <Notification show="md:hidden" />
              )}
              <AccountToolTip show="md:hidden" />
            </div>
          )}

          {!token && (
            <div className="flex items-center md:hidden ml-1 space-x-3">
              {showSearchBtn && !showSearchDarwer && (
                <button onClick={showDrawerHandler}>
                  <SearchIcon styles="w-6 h-6" />
                </button>
              )}

              <button onClick={burggerMenuHandler}>
                <BurggerMenu styles={"w-8 h-8 text-gray-600"} />
              </button>
            </div>
          )}
        </div>

        <div className="flex items-center relative">
          <div
            className={` ${
              menuExpanded && "mt-4"
            } flex items-center  md:space-x-6 lg:space-x-8 h-full mr-0 md:mr-0 lg:mr-0 mt-0`}
          >
            <div
              ref={myContentRef}
              className={`${
                menuExpanded ? "duration-100 " : "max-h-0"
              } overflow-hidden md:overflow-visible transition-max-height md:duration-0 md:max-h-full`}
            >
              <ul
                className={`flex flex-col justify-center md:flex-row space-y-2 md:space-y-0 md:space-x-6  text-gray-600 w-full lg:w-auto ${
                  token && "hidden md:flex"
                }`}
              >
                {(showSearchBtn || token) && !showSearchDarwer && (
                  <button
                    onClick={showDrawerHandler}
                    className="hidden md:inline lg:hidden"
                  >
                    <SearchIcon styles="w-6 h-6" />
                  </button>
                )}
                <Navigator onClick={colapseMenu} to={"tenders"}>
                  Tenders
                </Navigator>
                <Navigator
                  onClick={colapseMenu}
                  title={"Pricing"}
                  to={token ? "myfeed" : "pricing"}
                >
                  {token ? "My Feed" : "Pricing"}
                </Navigator>
                <Navigator onClick={colapseMenu} to={token ? "saved" : "FAQ"}>
                  {token && (
                    <div className="relative">
                      <SavedCount />
                    </div>
                  )}
                  {token ? "Saved Tenders" : "FAQ"}
                </Navigator>
                {!token && (
                  <Navigator onClick={colapseMenu} to={"signup"}>
                    Signup
                  </Navigator>
                )}
                {!token && (
                  <Navigator onClick={colapseMenu} to={"login"}>
                    <button className="bg-[#664bc7] hover:bg-[#664bc7]/90 text-white px-8 py-1.5 text-sm md:text-base rounded md:rounded-full">
                      Log In
                    </button>
                  </Navigator>
                )}
              </ul>
            </div>

            {token && (
              <div className="flex items-center space-x-5">
                {(userInfo.isActive === 0 ||
                  (!isVerified.email && userInfo.email)) && (
                  <Notification show="hidden md:inline" />
                )}
                <AccountToolTip onClick={colapseMenu} show="hidden md:inline" />
              </div>
            )}
          </div>
          <div className="hidden md:inline lg:hidden w-full absolute right-0"></div>
        </div>
      </div>
    </header>
  );
};

export default React.memo(NavigationBar);
