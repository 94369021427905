import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import LocationIcon from "../icons/Location";
import AttachmentIcon from "../icons/Attachment";

import CompanyProfileShimmer from "./CompanyProfileShimmer";

import logoPlaceholder from "../../assets/companyProfilePlaceholder.jpg";

const CompanyProfileCard = () => {
  const [isSending, setIsSending] = useState(false);
  const [data, setData] = useState([]);
  const params = useParams();

  useEffect(() => {
    setIsSending(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `https://api.ichereta.com/api/company/detail/${params.companyId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setIsSending(false);
        setData(result);
        document.title = result.name;
      })
      .catch((error) => {
        setIsSending(false);
        console.log("error", error);
      });
  }, [params.companyId]);

  const scrollTargetRef = useRef(null);

  const handleScrollToDiv = () => {
    if (scrollTargetRef.current) {
      scrollTargetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      {isSending ? (
        <CompanyProfileShimmer />
      ) : (
        <div className="w-full flex flex-col items-center text-[#3d5984]">
          <div
            style={{
              backgroundColor: data.brand_color ? data.brand_color : "#cbd5e1",
            }}
            className={`w-full h-40  md:h-52 rounded-md`}
          ></div>

          <div className="flex flex-col items-center bg-white -mt-20 rounded-md w-[85%] max-w-[500px] shadow space-y-3">
            <div
              style={{
                border: `4px solid ${
                  data.brand_color ? data.brand_color : "#cbd5e1"
                }`,
                borderColor: data.brand_color ? data.brand_color : "#cbd5e1",
              }}
              className="-mt-12 md:-mt-20 w-28 h-28 md:w-36 md:h-36 rounded-full bg-white flex items-center justify-center overflow-hidden"
            >
              <img
                className=""
                src={data.logo ? data.logo : logoPlaceholder}
                alt="company profile"
              />
            </div>
            <div className="flex flex-col items-center text-center space-y-2 md:space-y-3 px-5 pb-7">
              <h1 className="font-bold  text-sm md:text-lg">{data.name}</h1>
              <div className="flex  items-center space-x-2">
                <LocationIcon styles="w-4 h-4" />

                <p className="text-xs md:text-sm">{data.address}</p>
              </div>
              <div
                onClick={handleScrollToDiv}
                className="bg-green-500/10 px-4 py-1 rounded-full  text-green-500 font-bold text-xs md:text-[16px] flex items-center space-x-2"
              >
                <AttachmentIcon styles="w-4 h-4 md:w-5 md:h-5" />

                <p className="">
                  <span>{data.tenders_count}</span> Open Tenders
                </p>
              </div>
            </div>
          </div>
          {/* scroll to here */}
          <div
            ref={scrollTargetRef}
            className="pt-10 pb-5  text-sm md:text-base"
          >
            <h1>Tenders By {data.name}</h1>
          </div>
        </div>
      )}
    </>
  );
};

export default CompanyProfileCard;
