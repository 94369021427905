import { useContext } from "react";

// context
import context from "../../store/context";

// components
import CategoryButton from "../Buttons/CategoryButton";
import CategoryButtonShimmer from "../Buttons/CategoryButtonShimmer";

// dummy
import dummyData from "../../DUMMY";

// icons

const CategoriesSlide = (props) => {
  const {
    popularCategories,
    tendersPopularCategories,
    selectedCategory,
    setSelectedCategory,
    setSearchParams,
    searchIsClicked,
    setSearchIsClicked,
    selectedStatus,
    setSelectedStatus,
    getingPopularCategories,
  } = useContext(context);

  const categoryButtonClickHandler = (event) => {
    if (selectedStatus !== "" || searchIsClicked) {
      setSearchIsClicked(false);
      setSelectedStatus("");
    }

    setSelectedCategory(event.currentTarget.id);

    setSearchParams({
      title: "",
      categoryId: event.currentTarget.id,
      regionId: "",
      languageId: "",
      startDate: "",
      endDate: "",
      status: "",
      page: "",
      companyId: "",
    });
  };

  return (
    <div className="w-full flex items-center overflow-x-auto space-x-4 bg-white border-b border-[#664bc7]/10  px-2 sm:px-0 h-[50px] md:h-[60px] shadow-lg shadow-white">
      {!getingPopularCategories &&
        (popularCategories.length !== 0 ||
          (tendersPopularCategories.length !== 0 && props.filter === "")) && (
          <CategoryButton
            key="allTenders"
            id=""
            name="All Tenders"
            clickedId={selectedCategory}
            onClick={categoryButtonClickHandler}
          />
        )}
      {getingPopularCategories
        ? dummyData.map((item) => {
            return (
              <CategoryButtonShimmer key={item.id}></CategoryButtonShimmer>
            );
          })
        : props.filter === ""
        ? tendersPopularCategories.map((category) => {
            return (
              <CategoryButton
                key={category.id}
                clickedId={selectedCategory}
                onClick={categoryButtonClickHandler}
                id={category.id}
                name={category.name}
              ></CategoryButton>
            );
          })
        : popularCategories &&
          popularCategories.map((category) => {
            return (
              <CategoryButton
                key={category.id}
                clickedId={selectedCategory}
                onClick={categoryButtonClickHandler}
                id={category.id}
                name={category.name}
              ></CategoryButton>
            );
          })}
    </div>
  );
};

export default CategoriesSlide;
