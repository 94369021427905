import { useState, useContext } from "react";

import context from "../../store/context";

// section components
import RegistrationStepOne from "../../components/section/RegistrationStepOne";
import RegistrationConfirmation from "../../components/section/RegistrationConfirmation";

// components
import AuthenticationLayout from "./AuthenticationLayout";

// icons

const SignupPage = () => {
  const { cbePhone } = useContext(context);
  const [registrationStep, setRegistrationStep] = useState(1);
  const [stepOneData, setStepOneData] = useState({});

  const registrationStepHandler = () => {
    setRegistrationStep((prev) => {
      return prev + 1;
    });
  };

  const getStepOneData = (data) => {
    setStepOneData(data);
  };

  return (
    <AuthenticationLayout
      button="Log in"
      route="/"
      message="Already have iChereta Accounts?"
    >
      <div className="flex flex-col items-center  flex-grow w-full">
        <div className="flex flex-col space-y-8 pt-8 sm:shadow-sm bg-white sm:px-10 sm:pb-10 sm:rounded  w-[90%] max-w-[600px] relative">
          {registrationStep === 1 && (
            <div className="flex flex-col items-center">
              <h1 className="text-[#664bc7] text-lg md:text-xl  lg:text-2xl font-medium">
                አካውንት ይክፈቱ
              </h1>
              <h1>{cbePhone}</h1>
            </div>
          )}

          <div className="flex justify-center items-start flex-grow w-full -mt-5 md:mt-0 ">
            <div className="w-full bg-white flex flex-col justify-start ">
              {registrationStep === 1 && (
                <RegistrationStepOne
                  onClick={registrationStepHandler}
                  onSubmit={getStepOneData}
                  inputData={stepOneData}
                />
              )}
              {registrationStep === 2 && (
                <RegistrationConfirmation stepOneData={stepOneData} />
              )}
            </div>
          </div>
        </div>
      </div>
    </AuthenticationLayout>
  );
};

export default SignupPage;
