import { useState, useContext, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";

// context
import context from "../../store/context";
// icons
import PersonIcon from "../icons/Person";
import UserIcon from "../icons/User";
import SettingIcon from "../icons/Setting";
import BillingIcon from "../icons/Billing";
import LogoutIcon from "../icons/Logout";

const AccountToolTip = (props) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const { setToken, setFinishedRegistration, userInfo } = useContext(context);
  const tooltipRef = useRef(null);

  const tooltipToggleHandler = () => {
    setShowTooltip(!showTooltip);
  };

  const logout = () => {
    setShowTooltip(false);
    setToken("");
    setFinishedRegistration(false);
    sessionStorage.clear();
    window.location.href = "/";
  };

  const activeNavigation = "text-blue-500 flex space-x-2 text-sm sm:text-base";
  const inActiveNavigation =
    "hover:text-blue-500 flex space-x-2 text-sm sm:text-base";

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setShowTooltip(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className={`relative ${props.show} z-50`} ref={tooltipRef}>
      <button
        onClick={tooltipToggleHandler}
        className="flex items-center justify-center bg-[#664bc7]/20 py-2 w-8 h-8 lg:h-auto lg:w-[150px] text-[#664bc7] rounded-full lg:rounded relative"
      >
        <PersonIcon styles="w-5 h-5 lg:mr-2" />
        <span className="hidden lg:inline">My Account</span>
      </button>
      {showTooltip && (
        <div className="font-light inline text-base absolute top-10 md:top-12 right-0 w-[300px] sm:w-[350px] bg-white shadow border border-slate-100 rounded p-5 flex flex-col items-start text-[#3d5984] divide-y">
          <div className="w-full pb-2">
            <h1 className="font-semibold">{userInfo.name}</h1>
            <p className="text-xs sm:text-sm">{userInfo.phone}</p>
          </div>
          <div className="w-full pt-4 flex flex-col space-y-5">
            <NavLink
              onClick={tooltipToggleHandler}
              to="/myaccount"
              className={({ isActive }) =>
                isActive ? activeNavigation : inActiveNavigation
              }
              end
            >
              <UserIcon styles="w-6 h-6" />
              <h1>My Account</h1>
            </NavLink>
            <NavLink
              onClick={tooltipToggleHandler}
              to="/billing"
              className={({ isActive }) =>
                isActive ? activeNavigation : inActiveNavigation
              }
              end
            >
              <BillingIcon styles="w-6 h-6" />
              <h1>Billing</h1>
            </NavLink>
            <NavLink
              onClick={tooltipToggleHandler}
              to="/settings"
              className={({ isActive }) =>
                isActive ? activeNavigation : inActiveNavigation
              }
              end
            >
              <SettingIcon styles="w-6 h-6" />
              <h1>Settings</h1>
            </NavLink>
            <button
              onClick={logout}
              className="flex space-x-2 text-sm sm:text-base"
            >
              <LogoutIcon styles="w-6 h-6" />
              <h1>Logout</h1>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountToolTip;
