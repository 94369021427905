import { useContext } from "react";
import context from "../../store/context";

import cbeBirrLogo from "../../assets/cbebirr.png";

import "../../shimmer.css";

const RegistrationConfirmation = (props) => {
  const { HandleSubscription, isFetchingInvoice } = useContext(context);
  const { fName, amount, invoiceNumber } = props.stepOneData;

  return (
    <div className={`text-3xl text-center rounded flex flex-col`}>
      <div className="flex flex-col  text-[#664bc7] space-y-1.5 sm:space-y-3 pb-8">
        <span className="text-xl sm:text-3xl font-bold">ሰላም 👋 , {fName}!</span>
        <h1 className=" text-sm xl:text-xl">
          You've
          <span className="text-green-500 font-semibold mx-2">
            successfully
          </span>
          created your iChereta Account.
        </h1>
        <h1 className="text-base  text-gray-600 font-bold px-5">
          የiChereta አካውንቶ አክቲቭ አይደለም ፣ ጨረታዎችን ለመመልከት ክፍያ ይፍጽሙ!
        </h1>
      </div>

      <div className="w-full border border-gray-300 rounded-md relative text-sm p-5">
        <div className="absolute left-6 -top-3.5 bg-white px-2">
          Pay with CBE Birr
        </div>

        <div className="flex flex-col space-y-5 p-2 bg-blue-50/30 rounded-md w-full items-start">
          <span>
            <span className="font-bold ">Amount: </span>
            {amount} Birr
          </span>
          <span>
            <span className="font-bold ">Invoice No: </span>#{invoiceNumber}
          </span>
          <div className="flex items-center justify-center w-full space-x-3">
            <img src={cbeBirrLogo} alt="CBE Birr logo" className="w-12" />
          </div>
          <button
            onClick={HandleSubscription}
            className="relative animate-button  w-full text-white py-2 rounded text-base font-medium"
          >
            <span>
              Pay Now | <span className="font-bold text-lg">ይክፈሉ!</span>
            </span>

            {isFetchingInvoice && (
              <div className="absolute top-0 left-0 w-full h-full rounded bg-black/40 flex items-center justify-center">
                <div className=" animate-spin border-t border-white w-5 h-5 rounded-full"></div>
              </div>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegistrationConfirmation;
