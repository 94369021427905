// import { useContext } from "react";
// import { Link } from "react-router-dom";

// context
// import context from "../../store/context";

// images
import Logo from "../../assets/logowitht.svg";

const AuthenticationLayout = (props) => {
  // const { finishedRegistration } = useContext(context);
  return (
    <div className="w-full flex items-center bg-blue-50/40">
      <div className="flex flex-col justify-around w-full space-y-10 min-h-screen">
        <div className="flex flex-col items-center space-y-5">
          <img src={Logo} alt="logo of ichereta" className="w-48" />

          <div className="w-full h-full flex flex-col">{props.children}</div>
        </div>
        <div className="flex flex-col items-center  text-sm text-[#beb4e1] w-full lg:hidden">
          <span>© 2023 &mdash; iChereta™</span>
          <h1 className="font-semibold">
            <span className="font-normal">Powered by </span> HahuCloud
            Technologies PLC
          </h1>
        </div>
      </div>
    </div>
  );
};

export default AuthenticationLayout;
