import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import PrintableInvoice from "../../components/PrintableInvoice";

const Invoice = () => {
  const params = useParams();

  const userToken = sessionStorage.getItem("token");

  const [data, setData] = useState({});

  const getInvoice = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://api.ichereta.com/api/client/invoice/${params.invoiceId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setData(result.invoice);
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getInvoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-screen min-h-screen">
      {data ? (
        <PrintableInvoice
          amount={data.amount}
          invoiceNumber={data.invoice_number}
        />
      ) : (
        <div>loading</div>
      )}
    </div>
  );
};

export default Invoice;
