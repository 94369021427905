import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// context
import context from "../../store/context";

// hooks
import useInput from "../../hooks/use-Input";

// components
import Input from "../Input";
import RadioButtons from "./RadioButtons";
import CustomDropdown from "../Dropdown";
import AuthenticationButton from "../Buttons/AuthenticationButton";

//icons
import SearchIcon from "../icons/Search";
import LanguageIcon from "../icons/Language";
import TagIcon from "../icons/Tag";
import MapIcon from "../icons/Map";

const SearchSidebar = (props) => {
  const {
    categoryLists,
    regions,
    language,
    feedCategoryLists,
    feedRegions,
    feedLanguage,
    searchingTitle,
    setSearchingTitle,
    setSearchingCategoryId,
    setSearchingRegionId,
    setSearchingLanguageId,
    SearchTenders,
    selectedStatus,
    setSelectedStatus,
    tendersCategoryLists,
    tendersRegions,
    tendersLanguage,
  } = useContext(context);

  const { enteredValue, valueChangeHandler } = useInput(
    () => true,
    searchingTitle || ""
  );

  useEffect(() => {
    setSearchingTitle(() => enteredValue);
  }, [enteredValue, setSearchingTitle]);

  const handleOptionSelect = (option, dropdownId) => {
    if (dropdownId === 1) {
      setSearchingLanguageId(option);
    } else if (dropdownId === 2) {
      setSearchingRegionId(option);
    } else {
      if (option !== null) {
        setSearchingCategoryId(option);
      }
    }
  };

  const handleOptionChange = (event) => {
    setSelectedStatus(event.target.id);
  };

  const navigate = useNavigate();

  const searchSubmitHandler = (event) => {
    event.preventDefault();

    SearchTenders();
    if (props.redirect) {
      navigate("/tenders");
    }
  };
  return (
    <form type="submit" className="w-full flex flex-col">
      <div className="w-full md:h-[60px] mb-5 hidden md:flex items-center justify-center bg-[#664bc7]/5 text-[#664bc7]/70 text-lg rounded-b-md font-semibold">
        <h1>
          Search{" "}
          {props.filter === "myfeed"
            ? "My Feed"
            : props.filter === "saved"
            ? "Saved"
            : "Tenders"}
        </h1>
      </div>
      <Input
        onChange={valueChangeHandler}
        rounded="rounded-full"
        type="text"
        id="searchText"
        lable="Title"
      >
        <SearchIcon styles="w-6 h-6 mx-3 text-slate-400" />
      </Input>

      <div className="flex flex-col w-full space-y-7 text-gray-500">
        <div className="flex flex-col space-y-3">
          <div className="flex items-center">
            <LanguageIcon styles="w-4 h-4 mr-2" />
            <h1 className="font-medium text-sm  text-gray-500">Language</h1>
          </div>
          <CustomDropdown
            options={
              props.filter === ""
                ? tendersLanguage
                : props.filter === "myfeed"
                ? feedLanguage
                : language
            }
            defaultValue={"All"}
            onSelect={handleOptionSelect}
            dropdownId={1}
          />
        </div>
        <div className="flex flex-col space-y-3">
          <div className="flex items-center">
            <MapIcon styles="w-4 h-4 mr-2" />
            <h1 className="font-medium  text-sm text-gray-500">Regions</h1>
          </div>
          <CustomDropdown
            options={
              props.filter === ""
                ? tendersRegions
                : props.filter === "myfeed"
                ? feedRegions
                : regions
            }
            defaultValue={"All"}
            onSelect={handleOptionSelect}
            dropdownId={2}
          />
        </div>
        <div className="flex flex-col space-y-3">
          <div className="flex items-center">
            <TagIcon styles="w-4 h-4 mr-2" />
            <h1 className="font-medium text-sm  text-gray-500">Category</h1>
          </div>
          <CustomDropdown
            options={
              props.filter === ""
                ? tendersCategoryLists
                : props.filter === "myfeed"
                ? feedCategoryLists
                : categoryLists
            }
            defaultValue={"All"}
            onSelect={handleOptionSelect}
            dropdownId={3}
          />
        </div>
      </div>
      <div className="py-7 grid grid-cols-3 gap-6">
        <RadioButtons
          id=""
          onChange={handleOptionChange}
          name="All"
          active={selectedStatus}
          styles="border border-[#664bc7]/30"
        />
        <RadioButtons
          id="1"
          onChange={handleOptionChange}
          name="Open"
          active={selectedStatus}
          styles="border border-green-400/30"
        />
        <RadioButtons
          id="0"
          onChange={handleOptionChange}
          name="Closed"
          active={selectedStatus}
          styles="border border-red-400/30"
        />
      </div>
      <AuthenticationButton onClick={searchSubmitHandler} name="Search" />

      <div className="flex space-x-4"></div>
    </form>
  );
};

export default SearchSidebar;
