// Custom hook to get cookie data by name
function useCookie(cookieName) {
  const getCookie = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ");

    for (const cookie of cookies) {
      const [name, value] = cookie.split("=");
      if (name === cookieName) {
        return decodeURIComponent(value);
      }
    }

    return null;
  };

  const cookieValue = getCookie();

  return cookieValue;
}

export default useCookie;
