import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import context from "../../store/context";
//hooks

import useCookie from "../../hooks/use-cookies";

// layout
import AuthenticationLayout from "./AuthenticationLayout";

import AuthenticationButton from "../../components/Buttons/AuthenticationButton";

//icons
import ArrowIcon from "../../components/icons/Arrow";

const LoginPage = () => {
  const [isSending, setIsSending] = useState(false);

  const { setCbePhone, setToken } = useContext(context);

  const navigate = useNavigate();

  // let bearerToken;
  let bearerToken = useCookie("app_token");

  sessionStorage.setItem("cbeToken", bearerToken);

  const logUserIn = () => {
    setIsSending(true);
    var requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    fetch(
      `https://api.ichereta.com/api/miniapp/cbe-login?token=${bearerToken}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setIsSending(false);
        console.log(result);
        if (result.status) {
          sessionStorage.setItem("token", result.token);
          setToken(result.token);
          navigate("/tenders");
        } else {
          if (result.phone) {
            navigate("/signup");
            setCbePhone(result.phone);
          }
        }
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <AuthenticationLayout
      button="Sign Up"
      route="/signup"
      message="Don't have an account?"
    >
      <div className="w-full flex flex-col items-center space-y-5">
        <h1 className="font-bold text-lg text-[#5a4aa6]">ሁሉንም ጨረታ በአንድ ቦታ!</h1>
        <small className="text-center text-xs font-bold text-[#5a4aa6]">በመረጧቸው ዘርፎች ያሉ የጨረታ ዝርዝር መረጃዎችን በቀላሉ ይግኙ!</small>
        <div className=" animate-bounce">
          <ArrowIcon styles="w-8 h-8 rotate-90 text-[#5a4aa6]" />
        </div>
        <div className="w-[90%] max-w-[600px] bg-white flex flex-col sm:shadow-sm flex-grow  sm:p-10 md:rounded-md space-y-3 md:space-y-5 ">
          <AuthenticationButton
            process={isSending}
            name={<span className="font-bold">Login with CBE Birr</span>}
            onClick={logUserIn}
          />
        </div>
      </div>
    </AuthenticationLayout>
  );
};

export default LoginPage;
